.friendTrackerWrapper {
    height: calc(var(--small) * 10);
    width: calc(var(--small) * 10);
}

.friendTracker {
    height: calc(var(--small) * 8);
    width: calc(var(--small) * 8);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: var(--small);
    row-gap: var(--small);
    padding: calc(var(--small) * 2) var(--small) 0 var(--small);
}

.friendTracker .friend {
    background-color: #444;
    border-radius: 100%;
}

.friendTracker .ready.friend {
    background-color: mediumpurple;
}
