.stats {
    box-sizing: border-box;
    position: relative;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-evenly;*/
    justify-content: space-between;
    padding-top: 2rem;
    /*justify-content: center;*/
}

.stats .inputs {
    padding-bottom: 2rem;
}

.blessCurse {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;

}

.blessCurse > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blessCurse .bless > span {
    color: #ffcf58;
}

.variableToken {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.variableToken .autoFailTentacle {
    opacity: 0;
    transition: opacity 200ms ease-in;
}

.autoFail.variableToken .autoFailTentacle {
    opacity: 1;
}

.variableToken .numberSelect {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.variableToken .surgeButton {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .34rem;
}

.variableToken .surgeButton svg {
    height: 2rem;
    width: 2rem;
    fill: #ffffff30;
    transition:
            fill 200ms linear,
            opacity 200ms linear,
            filter 200ms linear;
    opacity: 1;
}

.surge.variableToken .surgeButton svg {
    fill: #ff9100;
    filter: drop-shadow(0 0 .1rem #ff6a00);
}

.autoFail.variableToken .surgeButton svg {
    opacity: 0;
}

.autoFail.variableToken .numberSelect {
    opacity: 0;
    pointer-events: none;
}

.variableTokens > div {
    opacity: 1;
    height: 12rem;
    transition: height 200ms linear, opacity 200ms linear;
    overflow: hidden;
}

.hidden.variableTokens > div {
    height: 1rem;
    pointer-events: none;
    opacity: 0;
}

.variableTokens > div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: calc(100vw - 2rem);
}

.probabilities {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.probabilities .ellipses {
    width: 100vw;
    text-align: center;
    margin: 0;
    font-size: 2rem;
}

.probabilities > div {
    display: grid;
    grid-template-columns: auto auto;
    font-size: 2rem;
    grid-auto-rows: 2rem;
    grid-gap: 1rem;
}

.chanceLabel {
    text-align: right;
}

.chanceValue {
    text-align: left;
    display: flex;
    align-items: center;
}

.chanceValue .improvement {
    margin-left: 1rem;
    font-size: 1rem;
}

.hideVariableTokensButtonWrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 1rem;

    pointer-events: none;

    display: flex;
    justify-content: center;
}

.hideVariableTokensButtonWrapper button {
    pointer-events: all;
    border-radius: 1rem;
    background-color: #00000000;
    border: none;
}

.hideVariableTokensButtonWrapper button path {
    transition: d 200ms linear;
}

.hideVariableTokensButtonWrapper button svg {
    height: 20px;
}
