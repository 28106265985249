/*
  STRUCTURE
*/
.chaosChance,
.chaosChanceSettings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.chaosChanceSettings {
  background-color: #333;
}


/*
  SETTINGS PAGE
*/

.tokenInputs {
  display: grid;
  grid-template-columns: auto auto auto;
  max-height: 100vh;
}

.tokenInputColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.separator {
  flex: 1;
  width: 1px;
  background-color: white;
  min-height: 100px;
  margin: 0 8px;
}

.tokenInputSpacer {
  height: 56px;
  margin: 4px 0;
}

.tokenInput {
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: white;
  margin: 4px 0;

  background-color: #555;
  border-radius: 2rem;
  padding: 4px 10px;
}

.tokenInput button {
  border: none;

  background: none;
  color: white;

  font-size: 1rem;

  width: 2rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.tokenInput span {
  display: inline-block;
  min-width: 1rem;
}

.tokenInput .label {
  font-size: 1.5rem;
  margin-right: 8px;

  color: white;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
}

.tokenInput .value {
  font-size: 2rem;
}

.chaosChanceSettings .options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minimumInterestingChance_header {
  color: #888;
  font-size: .9rem;
}


/*
  CHANCES
*/

.graph {
  width: 80vw;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.column {
  flex: 1;
  display: inline-block;
  height: 40vh;
  margin: 0 5px;
}

.bar {
  background-color: dodgerblue;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 8px;
}

.probabilityLabel {
  margin-bottom: 8px;
  padding: 0 4px;
}


.blessCurse > div > span > svg,
.tokenInput .label svg,
.variableToken span svg {
  --size: 64px;
  height: var(--size);
  width: var(--size);
}