.clickTrackerWrapper,
.settings {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings {
  background-color: #333;
  z-index: 2;
  flex-direction: column;
}

.clickTrackerWrapper {
  flex-direction: column;
}

.click-tracker-button {
  background-color: #555;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
}

.clickTracker .resetButton {
  position: absolute;
  top: 1rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  left: 1rem;
  transform: rotate(-90deg);
}


.clickTracker .resetButton > svg,
#settingsButton > svg {
  width: 50px;
  height: 50px;
}

.trackerContent,
.incrementors {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings {
  text-align: center;
}

.incrementors {
  flex-direction: column;
  justify-content: space-evenly;
}

.fullScreenButtonWrapper {
  padding: 1rem
}

.fullScreenButton {
  --pos: min(1vh, 2vw);

  padding: 0;
  background-color: #00000000;
  border: none;

  position: fixed;
  left: var(--pos);
  bottom: var(--pos);
  z-index: 3;
}

.fullScreenButton svg {
  --size: min(5vh, 10vw);
  height: var(--size);
  width: var(--size);
  fill: white
}

.incrementor .input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings h1 {
  font-size: min(10vh, 20vw);
  margin: min(1vh, 2vw);
  min-width: min(10vh, 20vw);
}

.settings h1 {
  transform: rotate(-90deg);
}

.settings .incrementor button {
  font-size: min(10vh, 20vw);
  --size: min(15vh, 30vw);

  border-radius: min(3vh, 6vw);

  width: var(--size);
  height: var(--size);
}

.settings .incrementor button {
  transform: rotate(-90deg);
}

.clicks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blueText {
  color: #1e90ff;
}

.redText {
  color: red;
}

.greenText {
  color: springgreen;
}

.click {
  border-radius: 100%;
  background-color: springgreen;
}

.click.spent {
  background-color: #222;
}

.ouchies {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  --mar: min(4vh, 4vw);
}


 .ouchies {
  margin-left: var(--mar);
  flex-direction: row;
}
.ouchies .row {
  flex-direction: column-reverse;
}


.bottomTrackers {
  display: flex;
  align-items: center;
  justify-content: center;

}

.boop {
  position: relative;
}

.boop p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boop svg {
  height: 100%;
  width: 100%;
  fill: #222
}

.boop svg {
  height: 100%;
  width: 100%;
}


.ouchSpacer {
  border-radius: 100%;
  margin: min(1vh, 1vw);
}


.clickTrackerWrapper {
  --small: max(2.5vw, 2.5vh);
  --med: calc(var(--small) * 3);
  --big: calc(var(--small) * 4);
}
.clickTrackerWrapper.landscape {
  transform: rotate(90deg);
}

.trackerContent,
.incrementors {
  height: calc(var(--small) * 30);
}

