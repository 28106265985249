* {
  box-sizing: border-box;
}

.App {
  background-color: #333;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grevlingPaneViewPane {
  position: relative;
}

.active.haptixButton {
  box-shadow: 0 0 15px dodgerblue;
}

.haptixButton {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
  background: none;
  /*background-color: red;*/
  border: none;
  padding: 5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.haptixButton svg {
  height: 30px;
  width: 30px;
}